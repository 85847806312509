@import 'variables'

.dashboard-page
  padding: 0.5rem

.dashboard-header-wrapper
  lost-flex-container: row
  lost-align: center

.dashboard-header-title
  lost-column: 1/2 4 0
  text-align: center

.dashboard-header-title__text
  margin: 1.2rem 0.5rem

@media (--small-viewport)
  .dashboard-header-logo
    lost-column: 1/2 2 0

  .dashboard-header-title
    lost-column: 1/2 2 0
