.github-corner:hover .github-corner-arm
  animation: octocat-wave 560ms ease-in-out

@keyframes octocat-wave
  0%,
  100%
    transform: rotate(0)

  20%,
  60%
    transform: rotate(-25deg)

  40%,
  80%
    transform: rotate(10deg)

@media (max-width: 500px)
  .github-corner
    .github-corner-arm
      animation: octocat-wave 560ms ease-in-out

    &:hover .github-corner-arm
      animation: none
