@import 'variables'

.main-convertor
  lost-flex-container: row

.main-convertor-form-wrapper
  lost-column: 1/2 2 3rem

.main-convertor-result-wrapper
  lost-column: 1/2 2 3rem

.main-convertor-form-subtitle
  color: $yellow
  text-align: center
  margin: 0.2rem 0

@media (--small-viewport)
  .main-convertor-form-wrapper
    lost-column: 1/1

  .main-convertor-result-wrapper
    lost-column: 1/1
