@import 'variables'

.uploader-box
  position: relative
  padding: 100px 20px

.uploader-box--advanced
  outline: 2px dashed var(--commentColor)
  outline-offset: -10px

.uploader-box--is-dragover
  outline-offset: -20px
  outline-color: var(--emphColor)
  background-color: var(--headsColor)

.uploader-box__wrapper
  text-align: center

.uploader-box__input
  width: 0.1px
  height: 0.1px
  opacity: 0
  overflow: hidden
  position: absolute
  z-index: -1

.uploader-box__dragndrop
  display: inline

.uploader-container-image
  margin: 15px 0

.uploader-container-image-title
  font-size: 1.2rem
  margin: 10px 0

.uploader-container-image-info
  font-size: 0.8rem
  lost-flex-container: row

.uploader-container-image-info__key
  lost-column: 1/3 3 0

.uploader-container-image-info__value
  lost-column: 2/3 3 0
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
