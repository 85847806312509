.app-update
  lost-flex-container: row
  border: 1px solid var(--commentColor)
  margin: 0.6rem

.app-update__title
  lost-column: 2/3
  lost-align: center
  text-align: center

.app-update__link-wrapper
  lost-column: 1/3
  lost-align: center
  text-align: center
  background-color: var(--headsColor)
  vertical-align: middle
